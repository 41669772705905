@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

body {
  @apply bg-black;
}

video {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.enact-fit {
  padding: 0 !important;
}

.spottable:focus {
  @apply bg-red-600 !important;
  @apply text-gray-200 !important;
}

[class*='Slider_slider'].spottable:focus {
  background: transparent !important;
}

[class*='Spinner_bg'] {
  background: transparent !important;
}

[class*='MoonstoneDecorator_root'] {
  font-size: 1.3rem !important;
  line-height: 1.75rem !important;
}

[class*='VideoPlayer_overlay'] {
  cursor: pointer;
}

[class*='Input_decorator'] {
  @apply m-0 !important;
}

[class*='Item_item'].hidden {
  display: none !important;
}

.bg-black {
  background-color: rgba(0, 0, 0, 1);
}

.bg-red-700 {
  background-color: rgba(185, 28, 28, 1);
}

.bg-black.bg-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-black.bg-opacity-70 {
  background-color: rgba(0, 0, 0, 0.7);
}

.text-gray-200 {
  color: rgba(229, 231, 235, 1);
}

.text-gray-500 {
  color: rgba(107, 114, 128, 1);
}

.text-red-600 {
  color: rgba(207, 6, 82, 1);
}

.text-green-600 {
  color: rgba(5, 150, 105, 1);
}

.text-yellow-600 {
  color: rgba(217, 119, 6, 1);
}

.text-blue-600 {
  color: rgba(37, 99, 235, 1);
}
