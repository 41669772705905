@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), local('MaterialIcons-Regular'), url(./assets/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  width: 1.5rem;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  line-height: inherit;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
}
